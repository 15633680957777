// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("E:\\projects\\andrewcrowe.nz\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("E:\\projects\\andrewcrowe.nz\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-main-js": () => import("E:\\projects\\andrewcrowe.nz\\src\\pages\\blog\\main.js" /* webpackChunkName: "component---src-pages-blog-main-js" */),
  "component---src-pages-casestudy-bulletinboard-js": () => import("E:\\projects\\andrewcrowe.nz\\src\\pages\\casestudy\\bulletinboard.js" /* webpackChunkName: "component---src-pages-casestudy-bulletinboard-js" */),
  "component---src-pages-casestudy-newspaper-js": () => import("E:\\projects\\andrewcrowe.nz\\src\\pages\\casestudy\\newspaper.js" /* webpackChunkName: "component---src-pages-casestudy-newspaper-js" */),
  "component---src-pages-casestudy-recipeapp-js": () => import("E:\\projects\\andrewcrowe.nz\\src\\pages\\casestudy\\recipeapp.js" /* webpackChunkName: "component---src-pages-casestudy-recipeapp-js" */),
  "component---src-pages-casestudy-tictactoeapp-js": () => import("E:\\projects\\andrewcrowe.nz\\src\\pages\\casestudy\\tictactoeapp.js" /* webpackChunkName: "component---src-pages-casestudy-tictactoeapp-js" */),
  "component---src-pages-contact-js": () => import("E:\\projects\\andrewcrowe.nz\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("E:\\projects\\andrewcrowe.nz\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-main-js": () => import("E:\\projects\\andrewcrowe.nz\\src\\pages\\portfolio\\main.js" /* webpackChunkName: "component---src-pages-portfolio-main-js" */),
  "component---src-pages-portfolio-template-js": () => import("E:\\projects\\andrewcrowe.nz\\src\\pages\\portfolio\\template.js" /* webpackChunkName: "component---src-pages-portfolio-template-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "E:\\projects\\andrewcrowe.nz\\.cache\\data.json")

